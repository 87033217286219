#root {
  max-width: 2480px;
  width: auto;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

/* Add a black background color to the top navigation */
.topnav {
  display:flex;
  background-color: #333;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: var(--bi-orange);
  color: white;
}

.header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
}

div.container {
  text-align: center;
}

ul.myUL {
  display: inline-block;
  text-align: left;
}


.footer {
  background-color: var(--bi-vit);      /* Bakgrundsfärg */
  color:var(--bi-gra);                /* Textfärg */
  padding: 0 0;             /* Mellanrum ovanför och under texten */
  font-size: 14px;             /* Textstorlek */
  text-align: center;          /* Centrerar texten horisontellt */
   border-top: 1px solid #ddd;  /*Lägger till en linje ovanför fotnoten */
  
}

.footer-row {
  display: flex;          /* Använd Flexbox för layout */
  justify-content: center; /* space-between = Dela upp utrymmet mellan kolumnerna maximalt */
  margin-bottom: 0px;    /* Lite utrymme mellan varje rad */
}

.footer-column {
  flex: 1;                /* Låter varje kolumn ta upp lika mycket utrymme */
  padding: 5px;
  /*margin: 0 10px;*/   /* Detta lägger till en marginal på 10px på båda sidor av varje kolumn */
  max-width: 50%; /* Sätt önskade maxbredden på varje "cell" i tabellen */
}

/* Klass för att alltid skala ner bilden så den får plats på skärmen oavsett skärmstorlek*/
.responsive-image {
  width: 100%;
  height: auto;
  max-width: 100%;
}

/* Media query for larger screens */
@media screen and (min-width: 768px) {
  .responsive-image {
    max-width: 40%; /* or 30% for even smaller images */
    margin: 0 auto;
  }
}

/* Defines custom CSS variables for the BI Empire application.*/
:root {
    --bi-svart: #000000;
    --bi-vit: #ffffff;
    --bi-gron: #04AA6D;
    --bi-bla: #0000ff;
    --bi-roda: #ff0000;
    --bi-gul: #ffff00;
    --bi-gra: #ddd;
    --bi-orange: #d7642a;
}

h1 {
  font-family:  "Lucida Handwriting", Cursive;
}

h2 {
  font-family:  "Lucida Handwriting", Cursive;
}

h3 {
  font-family:  "Lucida Handwriting", Cursive;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

/* Add these new styles */
/*.allmäninfo p {
  text-align: left;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .allmäninfo p {
    font-size: 14px;
    line-height: 1.4;
  }
  
  .allmäninfo br {
    display: none;
  }
}
  */
